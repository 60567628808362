import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class ButceKoduService {
  getButceKoduList(dataState, callback) {
    console.log(dataState);
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/ButceKodu/get-list-by-filter?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      })
      .catch((error) => {
        console.error("Veri çekilirken hata oluştu:", error);
        return { data: [], error };
      });
  }

  getListForExcel(dataState) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/ButceKodu/get-list-for-excel?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => response.data);
  }

  insert(product, callback) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/ButceKodu/add-butce-kodu`, product)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }

  update(product, callback) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/ButceKodu/update-butce-kodu`, product)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }
}

export default new ButceKoduService();
