import React from "react";
import { process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import NumberFormat from "react-number-format";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";

const SapOpenOrderProductList = ({ orderProductData }) => {
  
 
  const rowRender = (trElement, dataItem) => {
    const trProps = { ...trElement.props, className: "ellipsis" };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const NumberFormatCell = (value, dataItem, isPercent, isKDV) => {
    return (
      <td
        style={{
          fontStyle: dataItem.puto === "DET" ? "italic" : "normal",
          color: dataItem.puto === "DET" ? "gray" : "rgba(0,0,0,0.78)",
        }}
      >
        {isPercent ? "% " : ""}
        <NumberFormat
          thousandSeparator={"."}
          value={value ? (isKDV ? value * 100 : value) : 0}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale={2}
        />
      </td>
    );
  };

  const PutoCellNumber = (props) => {
    const { dataItem, field } = props;
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    return NumberFormatCell(dataValue, props.dataItem, false, false);
  };

  const getTotalByField = (field) => {
    return orderProductData
      .filter((a) => a.puto !== "DET")
      .reduce((acc, current) => acc + current[field], 0);
  };
  const FooterSumCellCurrency = (props) => {
    var total = getTotalByField(props.field);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam : <br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={total ? total : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        {/* {this.props.order._order.parA_BIRIMI} */}
      </td>
    );
  };

  return (
    <Card style={{ width: "100%", marginTop: "20px" }}>
      <CardBody>
        <CardTitle>Sipariş Ürün Listesi</CardTitle>
        <hr />
        <Grid
          data={orderProductData}
          sortable={true}
          style={{ height: "500px" }}
          resizable
          // filterable
          rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
        >
          <GridColumn
            field="uruN_KODU"
            title="Ürün Kodu"
            width="200px"
          />
           <GridColumn
            field="uruN_ADI"
            title="Ürün Adı"
            width="350px"
          />
          <GridColumn
          field="uruN_GRUBU_ADI"
          title="Ürün Grubu"
          width="350px"
        />
                <GridColumn
          field="satiR_NUMARASI"
          title="Satır Numarası"
          width="150px"
        />
          {/* <GridColumn
            field="adet"
            title="Adet"
            width="90px"
            filterable={false}
            editor="numeric"
          /> */}
          <GridColumn
            field="aciK_ADET"
            title="Açık Adet"
            width="90px"
            filterable={false}
            editor="numeric"
            footerCell={FooterSumCellCurrency}
          />
          <GridColumn
            field="toplaM_TUTAR"
            title="Toplam Fiyat"
            width="150px"
            filterable={false}
            editable={false}
            footerCell={FooterSumCellCurrency}
            cell={PutoCellNumber}
          />
         <GridColumn
        field="isteneN_TARIH"
        title="RDD Tarihi"
        filter="date"
        cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
        width="150px"
      />
         <GridColumn
        field="teslimaT_OLUSTURMA_TARIHI"
        title="Teslimat Oluşturma Tarihi"
        filter="date"
        cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
        width="150px"
      />
        </Grid>
      </CardBody>
    </Card>
  );
};

export default SapOpenOrderProductList;