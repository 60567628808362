export const ButceKoduColumns = [
  {
    field: "budgeT_CODE",
    title: "Bütçe Kodu",
    width: "256px",
  },
  {
    field: "aciklama",
    title: "Açıklama",
    width: "256px",
  },
  {
    field: "artI_FREE_STOCK_GUN",
    title: "Artı Free Srock Gün",
    width: "256px",
    editor: "numeric",
  },
];
