import { Row, Col } from "reactstrap";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import {
  DATALISTEVADESI,
  DATABRYSATISDEPARTMAN,
  DATABANKATAKSIT,
  DATAFATURAVADESI,
  DATAEXPORTSIPARISTIPI,
  DATAEXPORTSIPARISTURU,
  DATAEXPORTBOLGE,
  DATAINCOTERMS,
  DATAEXPORTSEVKSEKLI,
  DATASEVKSEKLI,
  DATAODEMEVADESI,
  DATASEVKZAMANI,
  DATASEVKARACTIPI,
  DATASEVKEDILMEMENEDENI,
  DATASEVKEDILMEMEDETAYI,
  DATAFATURAKURU,
  DATAODEMETIPI,
  DATASEVKADRESITIPI,
} from "../../config/globalVariables";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentTerms } from "../../redux/actions/act-paymentTerm/paymentTermActions";
import { useEffect } from "react";

export function OrderCopProps({ orderData }) {
  const dispatch = useDispatch();
  const paymentTerms = useSelector(
    (state) => state.paymentTermListReducer.paymentTerms
  );

  useEffect(() => {
    dispatch(getPaymentTerms());
  }, [dispatch]);

  console.log(paymentTerms);
  return (
    <Row>
      <Col lg="4" md="4" xs="12">
        <Input
          label="Sipariş No"
          name="brY_SIPARIS_ID"
          id="brY_SIPARIS_ID"
          style={{ width: "100%" }}
          value={orderData.brY_SIPARIS_ID}
          readOnly={true}
        ></Input>
        <Input
          name="bayI_ADI"
          label="Bayi Adı"
          style={{ width: "100%" }}
          value={`${orderData.bayI_ADI} (${orderData.bayI_KODU})`}
          readOnly={true}
        />
        <Input
          name="satiS_DEPARTMANI_KODU"
          label="Ana Ürün Grubu"
          style={{ width: "100%" }}
          value={
            orderData.satiS_DEPARTMANI_KODU
              ? DATABRYSATISDEPARTMAN.find(
                  (dbry) => dbry.id === orderData.satiS_DEPARTMANI_KODU
                ).text
              : ""
          }
          readOnly={true}
        />
        <Input
          name="brY_KANAL"
          label="Marka"
          style={{ width: "100%" }}
          value={orderData.brY_KANAL}
          readOnly={true}
        />
        <Input
          label="Satış Onay Durum"
          name="satiS_ONAY_DURUM"
          id="satiS_ONAY_DURUM"
          style={{ width: "100%" }}
          value={orderData.satiS_ONAY_DURUM}
          readOnly={true}
        ></Input>
        <DateInput
          label="Sipariş Giriş Tarihi"
          format="dd.MM.yyyy HH:mm"
          formatPlaceholder="formatPattern"
          value={new Date(orderData.crE_DATE)}
          style={{ width: "100%" }}
          readOnly={true}
        />
        <Input
          name="parA_BIRIMI"
          label="Para Birimi"
          style={{ width: "100%" }}
          value={orderData.parA_BIRIMI}
          readOnly={true}
        />
        <Input
          label="Satış Temsilcisi"
          name="satiS_TEMSILCISI"
          id="satiS_TEMSILCISI"
          style={{ width: "100%" }}
          value={orderData.satiS_TEMSILCISI}
          readOnly={true}
        ></Input>
        {orderData.kopyalanaN_SIPARIS_ID ? (
          <Input
            label="Kopyalanan Sipariş No"
            name="kopyalanaN_SIPARIS_ID"
            id="kopyalanaN_SIPARIS_ID"
            style={{ width: "100%" }}
            value={orderData.kopyalanaN_SIPARIS_ID}
            readOnly={true}
          ></Input>
        ) : undefined}
        {orderData.satiS_DEPARTMANI_KODU === "EXPORT_UPG" ? (
          <div>
            <NumericTextBox
              label="Freight"
              name="navluN_FIYATI"
              id="navluN_FIYATI"
              style={{ width: "100%" }}
              value={orderData.navluN_FIYATI ? orderData.navluN_FIYATI : 0}
              readOnly={true}
            ></NumericTextBox>
            <NumericTextBox
              label="Insurance"
              name="sigortA_UCRETI"
              id="sigortA_UCRETI"
              style={{ width: "100%" }}
              value={orderData.sigortA_UCRETI ? orderData.sigortA_UCRETI : 0}
              readOnly={true}
            ></NumericTextBox>
            <Input
              label="Purchase Order Number"
              name="satiN_ALMA_SIPARIS_NO"
              id="satiN_ALMA_SIPARIS_NO"
              style={{ width: "100%" }}
              value={orderData.satiN_ALMA_SIPARIS_NO}
              readOnly={true}
            ></Input>
          </div>
        ) : undefined}
      </Col>
      <Col lg={{ size: 4 }} md="4" xs="12">
        <Input
          name="sipariS_TURU"
          label="Sipariş Türü"
          style={{ width: "100%" }}
          value={orderData.sipariS_TURU}
          readOnly={true}
        />
        {orderData.satiS_DEPARTMANI_KODU !== "EXPORT_UPG" ? (
          <Input
            name="listE_FIYATI_VADESI"
            label="Liste Fiyatı Vadesi"
            style={{ width: "100%" }}
            value={
              orderData.satiS_DEPARTMANI_KODU === "EXPORT_UPG"
                ? ""
                : orderData.listE_FIYATI_VADESI ||
                  orderData.listE_FIYATI_VADESI === 0
                ? DATALISTEVADESI.find(
                    (a) => a.id === orderData.listE_FIYATI_VADESI.toString()
                  ).text
                : ""
            }
            readOnly={true}
          />
        ) : undefined}
        {orderData.sipariS_TURU === "KAMPANYA" ? (
          <div>
            <Input
              name="kampanyA_KODU"
              label="Kampanya"
              style={{ width: "100%" }}
              value={orderData.kampanyA_KODU}
              readOnly={true}
            />
            <Input
              label="Kampanya Bilgisi"
              name="kampanyA_KOSULU"
              id="kampanyA_KOSULU"
              style={{ width: "100%" }}
              value={orderData.kampanyA_KOSULU}
              readOnly={true}
            ></Input>
          </div>
        ) : undefined}
        {orderData.satiS_DEPARTMANI_KODU === "EXPORT_UPG" ? (
          <div>
            <Input
              name="exporT_SIPARIS_TIPI"
              label="Export Sipariş Tipi"
              style={{ width: "100%" }}
              value={
                orderData.exporT_SIPARIS_TIPI
                  ? DATAEXPORTSIPARISTIPI.find(
                      (a) => a.id === orderData.exporT_SIPARIS_TIPI
                    ).text
                  : ""
              }
              readOnly={true}
            />
            <Input
              name="exporT_SIPARIS_TURU"
              label="Export Sipariş Türü"
              style={{ width: "100%" }}
              value={
                orderData.exporT_SIPARIS_TURU
                  ? DATAEXPORTSIPARISTURU.find(
                      (a) => a.id === orderData.exporT_SIPARIS_TURU
                    ).text
                  : ""
              }
              readOnly={true}
            />
            <Input
              name="exporT_BOLGE_KODU"
              label="Export Bölge Kodu"
              style={{ width: "100%" }}
              value={
                orderData.exporT_BOLGE_KODU
                  ? DATAEXPORTBOLGE.find(
                      (a) => a.id === orderData.exporT_BOLGE_KODU
                    ).text
                  : ""
              }
              readOnly={true}
            />
            <Input
              name="incoterms"
              label="Incoterms"
              style={{ width: "100%" }}
              value={
                orderData.incoterms
                  ? DATAINCOTERMS.find((a) => a.id === orderData.incoterms).text
                  : ""
              }
              readOnly={true}
            />
            <div>Incoterms2</div>
            <TextArea
              placeholder="Incoterms 2"
              id="incotermS2"
              name="incotermS2"
              autoSize={false}
              style={{ width: "100%" }}
              value={orderData.incotermS2}
              readOnly={true}
            ></TextArea>
            <Input
              name="sevK_YAPILACAK_ULKE"
              label="Sevk Yapılacak Ülke"
              style={{ width: "100%" }}
              value={orderData.sevK_YAPILACAK_ULKE}
              readOnly={true}
            />
            <Input
              name="odemE_KOSULU"
              label="Ödeme Koşulu"
              style={{ width: "100%" }}
              value={
                orderData?.odemE_KOSULU
                  ? paymentTerms?.find(
                      (a) => a.kosuL_KODU === orderData?.odemE_KOSULU
                    )?.kosuL_ACIKLAMA
                  : ""
              }
              readOnly={true}
            />
          </div>
        ) : undefined}
        {orderData.satiS_DEPARTMANI_KODU !== "EXPORT_UPG" ? (
          <Input
            name="odemE_VADESI_TIPI"
            label="Ödeme Vadesi Tipi"
            style={{ width: "100%" }}
            value={
              orderData.odemE_VADESI_TIPI
                ? DATAODEMEVADESI.find(
                    (a) => a.id === orderData.odemE_VADESI_TIPI
                  ).text
                : ""
            }
            readOnly={true}
          />
        ) : undefined}
        {orderData.odemE_VADESI_TIPI === "FATURA_VADESI" ? (
          <Input
            name="faturA_VADESI"
            label="Fatura Vadesi"
            style={{ width: "100%" }}
            value={
              DATAFATURAVADESI.find(
                (a) => a.id === orderData.faturA_VADESI.toString()
              )?.text || ""
            }
            readOnly={true}
          />
        ) : undefined}
        {orderData.odemE_VADESI_TIPI === "NOKTA_VADE" ? (
          <div>
            <div>Ödeme Vadesi Tarihi</div>
            <DatePicker
              label="Ödeme Vadesi Tarihi"
              name="odemE_VADE_TARIHI"
              format="dd.MM.yyyy"
              formatPlaceholder="formatPattern"
              min={new Date()}
              value={
                orderData.odemE_VADE_TARIHI != null
                  ? orderData.odemE_VADE_TARIHI === ""
                    ? null
                    : new Date(orderData.odemE_VADE_TARIHI)
                  : null
              }
              style={{ width: "100%" }}
              readOnly={true}
            />
          </div>
        ) : undefined}
        <Input
          name="odemE_TIPI"
          label="Ödeme Tipi"
          style={{ width: "100%" }}
          value={
            orderData.odemE_TIPI
              ? DATAODEMETIPI.find((a) => a.id === orderData.odemE_TIPI).text
              : ""
          }
          readOnly={true}
        />
        <Input
          name="faturA_KURU"
          label="Fatura Para Birimi"
          style={{ width: "100%" }}
          value={
            orderData.faturA_KURU
              ? DATAFATURAKURU.find((a) => a.id === orderData.faturA_KURU).text
              : ""
          }
          readOnly={true}
        />
        {orderData.bankA_ADI ? (
          <Input
            name="bankA_ADI"
            label="Banka"
            style={{ width: "100%" }}
            value={orderData.bankA_ADI}
            readOnly={true}
          />
        ) : undefined}
        {orderData.taksiT_SAYISI &&
        orderData.satiS_DEPARTMANI_KODU !== "EXPORT_UPG" ? (
          <Input
            name="taksiT_SAYISI"
            label="Taksit Sayısı"
            style={{ width: "100%" }}
            value={
              orderData.taksiT_SAYISI
                ? DATABANKATAKSIT.find(
                    (a) => a.id === orderData.taksiT_SAYISI.toString()
                  )?.text
                : null
            }
            readOnly={true}
          />
        ) : undefined}
        <Input
          name="sevK_SEKLI"
          label="Sevk Şekli"
          style={{ width: "100%" }}
          value={
            orderData.sevK_SEKLI
              ? orderData?.satiS_DEPARTMANI_KODU?.includes("EXPORT")
                ? DATAEXPORTSEVKSEKLI.find((a) => a.id === orderData.sevK_SEKLI)
                    .text
                : DATASEVKSEKLI.find((a) => a.id === orderData.sevK_SEKLI).text
              : ""
          }
          readOnly={true}
        />
        <Input
          name="toplaM_HACIM"
          label="Toplam Hacim (m³)"
          style={{ width: "100%" }}
          value={orderData.toplaM_HACIM}
          readOnly={true}
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <DateInput
          label="İstenen Sevk Tarihi"
          name="sevK_TARIHI"
          format="dd.MM.yyyy"
          formatPlaceholder="formatPattern"
          value={
            orderData.sevK_TARIHI != null
              ? orderData.sevK_TARIHI === ""
                ? null
                : new Date(orderData.sevK_TARIHI)
              : null
          }
          style={{ width: "100%" }}
          readOnly={true}
        />
        <Input
          name="sevK_ADRESI_TIPI"
          label="Sevk Adresi Tipi"
          style={{ width: "100%" }}
          value={
            orderData.sevK_ADRESI_TIPI
              ? DATASEVKADRESITIPI.find(
                  (a) =>
                    a.adreS_BASLIGI === orderData.sevK_ADRESI_TIPI.toString()
                )?.adreS_TEXT
              : null
          }
          readOnly={true}
        />
        {!orderData?.satiS_DEPARTMANI_KODU?.includes("EXPORT") && (
          <Input
            label="Sevk Araç Tipi"
            name="sevK_ARAC_TIPI"
            id="sevK_ARAC_TIPI"
            style={{ width: "100%" }}
            value={
              orderData.sevK_ARAC_TIPI
                ? DATASEVKARACTIPI.find(
                    (a) => a.id === orderData.sevK_ARAC_TIPI
                  ).text
                : ""
            }
            readOnly={true}
          ></Input>
        )}
        <Input
          label="Sevk Zamanı"
          name="sevK_ZAMANI"
          id="sevK_ZAMANI"
          style={{ width: "100%" }}
          value={
            orderData.sevK_ZAMANI
              ? DATASEVKZAMANI.find((a) => a.id === orderData.sevK_ZAMANI).text
              : ""
          }
          readOnly={true}
        ></Input>
        <div>Sevk Notu</div>
        <TextArea
          placeholder="Sevk Notu"
          id="sevK_ADRESI"
          name="sevK_ADRESI"
          autoSize={false}
          style={{ width: "100%" }}
          value={orderData.sevK_ADRESI}
          readOnly={true}
        ></TextArea>
        <div>
          <div>Açıklama</div>
          <TextArea
            placeholder="Açıklama"
            id="aciklama"
            name="aciklama"
            autoSize={false}
            style={{ width: "100%" }}
            value={orderData.aciklama}
            readOnly={true}
          ></TextArea>
        </div>
        <div>
          <div>Plant</div>
          <TextArea
            placeholder="Plant"
            id="plant"
            name="plant"
            autoSize={false}
            style={{ width: "100%" }}
            value={orderData.plant}
            readOnly={true}
          ></TextArea>
        </div>
        <Input
          label="Sevk Edilmeme Nedeni"
          name="sevK_EDILMEME_NEDENI"
          id="sevK_EDILMEME_NEDENI"
          style={{ width: "100%" }}
          value={
            orderData.sevK_EDILMEME_NEDENI
              ? DATASEVKEDILMEMENEDENI.find(
                  (a) => a.id === orderData.sevK_EDILMEME_NEDENI
                ).text
              : ""
          }
          readOnly={true}
        ></Input>
        <Input
          label="Sevk Edilmeme Detayı"
          name="sevK_EDILMEME_DETAYI"
          id="sevK_EDILMEME_DETAYI"
          style={{ width: "100%" }}
          value={
            orderData.sevK_EDILMEME_DETAYI
              ? DATASEVKEDILMEMEDETAYI.find(
                  (a) => a.id === orderData.sevK_EDILMEME_DETAYI
                ).text
              : ""
          }
          readOnly={true}
        ></Input>
        <div>
          <div>Sevk Edilmeme Açıklama</div>
          <TextArea
            placeholder="Sevk Edilmeme Açıklama"
            id="sevK_EDILMEME_ACIKLAMA"
            name="sevK_EDILMEME_ACIKLAMA"
            autoSize={false}
            style={{ width: "100%" }}
            value={orderData.sevK_EDILMEME_ACIKLAMA}
            readOnly={true}
          ></TextArea>
        </div>
        <DateInput
          label="Revize İstenen Teslim Tarih"
          name="revizE_SEVK_TARIHI"
          format="dd.MM.yyyy"
          formatPlaceholder="formatPattern"
          value={
            orderData.revizE_SEVK_TARIHI != null
              ? orderData.revizE_SEVK_TARIHI === ""
                ? null
                : new Date(orderData.revizE_SEVK_TARIHI)
              : null
          }
          style={{ width: "100%" }}
          readOnly={true}
        />
        {orderData.satiS_DEPARTMANI_KODU === "EXPORT_UPG" ? (
          <div>
            <div>Proforma Açıklama</div>
            <TextArea
              placeholder="Proforma Açıklama"
              id="proformA_ACIKLAMA"
              name="proformA_ACIKLAMA"
              autoSize={false}
              style={{ width: "100%" }}
              rows={6}
              value={
                !orderData.proformA_ACIKLAMA ||
                orderData.proformA_ACIKLAMA === ""
                  ? "Brand: \nLATEST DATE OF SHIPMENT: \nOFFER VALIDATY: \nAFTER SALES CONDITIONS: \nPACKAGING: \nADDITIONAL INFORMATION:"
                  : orderData.proformA_ACIKLAMA
              }
              readOnly={true}
            ></TextArea>
          </div>
        ) : undefined}
      </Col>
    </Row>
  );
}
