import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import CountryService from "../../services/srv-country/CountryService";

const CountryDropDown = ({ id, label, required, onChange, value , disabled}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    getDataByFilter();
  }, []);

  const filterData = (e) => {
    setFilteredData(filterBy(data, e.filter));
  };
  const getDataByFilter = () => {
    setDataLoading(true);
    CountryService.getCountries(defaultDataHandler);
  };

  const defaultDataHandler = ({ data }) => {
    data.sort((a, b) => (a.ulkE_KODU > b.ulkE_KODU ? 1 : -1));
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  return (
    <DropDownList
      disabled = {disabled}
      name={id}
      id={id}
      label={label}
      data={filteredData}
      textField="ulkE_ADI"
      dataItemKey="ulkE_KODU"
      style={{ width: "100%" }}
      required={required}
      filterable={true}
      onChange={(e) => onChange(e)}
      onFilterChange={filterData}
      loading={dataLoading}
      value={value ? data.find((v) => v.ulkE_KODU === value) : ""}
    />
  );
};

export default CountryDropDown;
