import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import SapOpenOrderService from "../../services/srv-sap-open-order/SapOpenOrderService";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";

const SapOpenOrderApproval = ({ sapNo }) => {

    const [historyList, setHistoryList] = useState([])

    useEffect(() => {
        getHistoryBySapNo();
      }, []);

    function getHistoryBySapNo() {
        SapOpenOrderService.getApprovalHistoryBySapNo(sapNo, onCompleteGetHistory)
    }

    function onCompleteGetHistory(data){
        setHistoryList(data)
    }

    return (historyList.length > 0 && <Card>
        <CardBody>
        <CardTitle>Onay Red Geçmişi</CardTitle>
        <hr></hr>
        <Grid
          data={historyList || []}
          sortable={false}
          resizable={false}
        >
          <GridColumn
            field="onaycI_UNVAN"
            title="Onay Hiyerarşisi"
          ></GridColumn>
          <GridColumn
            field="onaycI_ADSOYAD"
            title="Ad Soyad"
          ></GridColumn>
          <GridColumn field="onaY_RED" title="Onay/Red"></GridColumn>
          <GridColumn
            field="onaY_RED_TARIHI"
            title="Onay Red Tarihi"
            cell={(props) =>
              GridDateFormatCell(props, "dd.MM.yyyy HH:mm")
            }
          ></GridColumn>
          <GridColumn field="aciklama" title="Açıklama"></GridColumn>
        </Grid>
        </CardBody>
        </Card>)
};
export default SapOpenOrderApproval;
