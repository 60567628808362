import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class BiggSerialsCsvService {
  getBiggSerialsCsvReports(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/biggcsv/get-list-by-filter?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
  getBiggSerialsCsvReportsForExcel(dataState) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/biggcsv/get-list-for-excel?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        return response.data;
      });
  }
  delete(id) {
    return axios
      .delete(`${API_GATEWAY + PR_DEX}/biggcsv/delete?id=${id}`)
      .then((response) => {
        return response.data;
      });
  }
}

export default new BiggSerialsCsvService();
