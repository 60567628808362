import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import { Window } from "@progress/kendo-react-dialogs";
import SapOpenOrderService from "../../services/srv-sap-open-order/SapOpenOrderService";

const SapOpenOrderApproval = ({ sapNo, showSatisTemsilcisiApproval,refreshOrder }) => {
    const [showForm, setShowForm] = useState(false);
    const [showCancelWindow,setShowCancelWindow] = useState(false);
    const [selectedReason, setSelectedReason] = useState("");
    const [description, setDescription] = useState("");
    const reasons = ["Ay Sonuna Kadar Sevk Edilecek", "Stok Problemi (Ürün Eksikliği)", "Satış Yönetim Stratejisi", "Proje Parsiyel Sevkiyat Problemi"]; // Dropdown için nedenler


    function toggleForm() {
        setShowForm(!showForm);
        setDescription("");
        setSelectedReason("");
    }

    function toggleCancelWindow(){
        setShowCancelWindow(!showCancelWindow)
    }
    function sapAcikSiparisIslem(islem,e) {
           e && e.preventDefault();
         
        var sapAcikSiparisIslem = {
            ISLEM: islem,
            SAP_SIPARIS_NO: sapNo,
            ACIKLAMA: description,
            IPTAL_NEDENI: selectedReason
        };

        SapOpenOrderService.executeSapOpenOrderProcess(sapAcikSiparisIslem, onSpExecuteComplete)
    }
    
    function onSpExecuteComplete(){
        toggleForm()
        refreshOrder && refreshOrder()
    }
    return (<Card style={{ marginTop: "20px" }}>
        <CardBody >
            {showSatisTemsilcisiApproval ?
                // Satış Temsilcisine Gösterilecek Kısım
                <div className="d-flex flex-column align-items-center">
                    <CardTitle>
                        Bir gün içerisinde yanıt verilmemesi halinde sipariş "N-2"
                        nedeni ile iptal edilecektir. Siparişin iptalini
                        onaylıyor musunuz?
                    </CardTitle>
                    <hr />
                    <div>
                        <Button
                            color="success"
                            onClick={() => toggleCancelWindow()}
                            style={{ marginRight: "10px" }}
                        >
                            <i className="k-icon k-i-check"></i> EVET
                        </Button>
                        <Button color="danger" onClick={toggleForm}>
                            <i className="k-icon k-i-times"></i> HAYIR
                        </Button>
                    </div>

                    {showForm && (
                        <Window
                            onClose={toggleForm}
                            initialHeight={"50vh"}
                            initialWidth={"30%"}
                            style={{
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                                position: "fixed",
                            }}
                        >
                            <Form onSubmit={(e) => sapAcikSiparisIslem("ONAYA_GONDER",e)} style={{ marginTop: "20px" }}>
                                <FormGroup>
                                    <Label for="reason">İptal Edilmeme Sebebi</Label>
                                    <Input
                                        type="select"
                                        id="reason"
                                        value={selectedReason}
                                        required
                                        onChange={(e) => setSelectedReason(e.target.value)}
                                    >
                                        <option value="">Seçiniz</option>
                                        {reasons.map((reason, index) => (
                                            <option key={index} value={reason}>
                                                {reason}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="description">Açıklama</Label>
                                    <Input
                                        type="textarea"
                                        id="description"
                                        value={description}
                                        required
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder="Açıklama yazınız..."
                                    />
                                </FormGroup>
                                <div>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        style={{ marginRight: "10px" }}
                                    >
                                        ONAYA GONDER
                                    </Button>
                                    <Button color="secondary" onClick={toggleForm}>
                                        IPTAL
                                    </Button>
                                </div>
                            </Form>
                        </Window>
                    )}
                    {showCancelWindow && (
                        <Window
                            onClose={toggleCancelWindow}
                            initialHeight={"20vh"}
                            initialWidth={"30%"}
                            style={{
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                                position: "fixed",
                            }}
                        >
                            <p style={{ fontSize: "large" }}>Sipariş iptal edilecektir. İptal etmek istediğinize  emin misiniz?</p>
                            <hr />
                            <div className="d-flex justify-content-center">
                                <Button
                                    color="success"
                                    onClick={() => sapAcikSiparisIslem("IPTAL")}
                                    style={{ marginRight: "10px" }}
                                >
                                    <i className="k-icon k-i-check"></i> EVET
                                </Button>
                                <Button color="danger" onClick={toggleCancelWindow}>
                                    <i className="k-icon k-i-times"></i> HAYIR
                                </Button>
                            </div>
                        </Window>
                    )}
                    </div>
                // Onaycıya Gösterilecek Kısım
                : <>
                    <CardTitle>ONAY / RED</CardTitle>
                    <div className="d-flex flex-column align-items-center">
                    <FormGroup style={{ width: "50%" }}>
                        <Label for="onay-red-description"> Onay/Red Açıklaması</Label>
                        <Input
                            type="textarea"
                            id="onay-red-description"
                            value={description}
                            required
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Açıklama yazınız..."
                        />
                    </FormGroup>
                    <div style={{ marginTop: "10px", textAlign: "center" }}>
                        <Button
                            type="button"
                            color="success"
                            size="md"
                            onClick={() => sapAcikSiparisIslem("ONAY")}
                        >
                            <i className="k-icon k-i-check"></i> ONAYLA
                        </Button>
                        <Button
                            type="button"
                            color="danger"
                            size="md"
                            style={{ marginLeft: "5px" }}
                            onClick={() => sapAcikSiparisIslem("RED")}
                        >
                            <i className="k-icon k-i-times"></i> REDDET
                        </Button>
                    </div>
                    </div>
                   </>
            }
        </CardBody>
    </Card>)
};
export default SapOpenOrderApproval;