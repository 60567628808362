import { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import OrderService from "../../services/srv-order/OrderService";
import { OrderCopProps } from "./OrderCopProps";
import OrderResposiblePerson from "../cmp-order/OrderResposiblePerson";
import OrderReport from "../cmp-order/OrderReport";
import OrderSAPStatus from "../cmp-order/OrderSAPStatus";
import OrderCopProductList from "../cmp-order-cop/OrderCopProductList";
import { getOrderProducts } from "../../redux/actions/act-order/orderProductActions";
import OrderAddressInfo from "../cmp-order/OrderAddressInfo";
import { OrderFiles } from "../cmp-order/OrderFiles";
import OrderCopReviseStatus from "./OrderCopReviseStatus";
import { BASE_URL } from "../../config/globalVariables";
import OrderRPAReport from "../cmp-order/OrderRPAReport";

const OrderCopUpdate = (props) => {
  const orderId = props.match.params.orderId;

  const [orderLoading, setOrderLoading] = useState(true);
  const [order, setOrder] = useState({});

  const [visibleOrderFiles, setVisibleOrderFiles] = useState(false);
  const [visibleOrderDaikinFiles, setVisibleOrderDaikinFiles] = useState(false);
  const [visibleOrderRPAReport, setVisibleOrderRPAReport] = useState(false);
  const [visibleOrderRPAReportNew, setVisibleOrderRPAReportNew] =
    useState(false);
  const [visibleResponsiblePerson, setVisibleResponsiblePerson] =
    useState(false);
  const [visibleOrderSAPStatus, setVisibleOrderSAPStatus] = useState(false);
  const [visibleAddressInfo, setVisibleAddressInfo] = useState(false);
  const [visibleOrderCopReviseStatus, setVisibleOrderCopReviseStatus] =
    useState(false);
  const [visibleSalesApproveFile, setVisibleSalesApproveFile] = useState(false);

  useEffect(() => {
    getOrderCopById();
  }, []);

  function onCompletedGetOrderCopById(data) {
    setOrder(data);
    setOrderLoading(false);
  }

  function getOrderCopById() {
    setOrderLoading(true);
    OrderService.getOrderCopById(orderId, onCompletedGetOrderCopById);
    props.getOrderProducts(orderId);
  }

  function refreshProductList() {
    props.getOrderProducts(orderId);
  }

  function toggleDialogOrderFiles() {
    setVisibleOrderFiles(!visibleOrderFiles);
  }

  function toggleDialogDaikinOrderFiles() {
    setVisibleOrderDaikinFiles(!visibleOrderDaikinFiles);
  }

  function toggleDialogResponsiblePerson() {
    setVisibleResponsiblePerson(!visibleResponsiblePerson);
  }

  function toggleDialogOrderSAPStatus() {
    setVisibleOrderSAPStatus(!visibleOrderSAPStatus);
  }

  function toggleDialogOrderRPAReport() {
    setVisibleOrderRPAReport(!visibleOrderRPAReport);
  }

  function toggleDialogOrderRPAReportNew() {
    setVisibleOrderRPAReportNew(!visibleOrderRPAReportNew);
  }

  function toggleDialogAdressInfo() {
    setVisibleAddressInfo(!visibleAddressInfo);
  }

  function toggleDialogOrderCopReviseStatus() {
    setVisibleOrderCopReviseStatus(!visibleOrderCopReviseStatus);
  }

  function toggleDialogSalesApproveFile() {
    setVisibleSalesApproveFile(!visibleSalesApproveFile);
  }

  function onCallbackSAPStatusUpdate() {
    toggleDialogOrderSAPStatus();
  }

  function onCallbackGeriGonder() {
    setTimeout(() => {
      window.location.href = BASE_URL + "order-cop-list";
    }, 250);
  }

  function sendToRPA() {
    setOrderLoading(true);
    OrderService.sendToRPA(parseInt(orderId), onCallbackSendToRPA);
  }

  function onCallbackSendToRPA() {
    setTimeout(() => {
      setOrderLoading(false);
      getOrderCopById();
    }, 250);
  }

  function CallDknSapRpaSirapisIslemleri(){
    OrderService.CallDknSapRpaSirapisIslemleri(parseInt(orderId) , toggleDialogOrderRPAReportNew)
  }

  return (
    <Fragment>
      {orderLoading && LoadingPanel}
      {visibleOrderFiles ? (
        <OrderFiles
          toggleDialog={toggleDialogOrderFiles}
          title={"Sipariş Dosyaları"}
          orderData={order}
          fileType={"SIPARIS"}
          auth={props.auth}
        ></OrderFiles>
      ) : (
        ""
      )}
      {visibleOrderDaikinFiles ? (
        <OrderFiles
          toggleDialog={toggleDialogDaikinOrderFiles}
          title="Daikin Sipariş Dosyaları"
          orderData={order}
          fileType="SIPARIS_DAIKIN"
          auth={props.auth}
        />
      ) : (
        ""
      )}
      {visibleSalesApproveFile ? (
        <OrderFiles
          toggleDialog={toggleDialogSalesApproveFile}
          title="Satış Onay Dokümanı"
          orderData={order}
          fileType="SIPARIS_SATIS_ONAY"
          auth={props.auth}
        />
      ) : (
        ""
      )}
      {visibleResponsiblePerson ? (
        <OrderResposiblePerson
          toggleDialog={toggleDialogResponsiblePerson}
          title="Sipariş Yetkili Kişi Bilgileri"
          orderId={orderId}
        ></OrderResposiblePerson>
      ) : (
        ""
      )}
      {visibleOrderSAPStatus ? (
        <OrderSAPStatus
          toggleDialog={toggleDialogOrderSAPStatus}
          title="Sipariş SAP Giriş Durumu"
          data={order}
          onCallback={onCallbackSAPStatusUpdate}
        />
      ) : (
        ""
      )}
      {/* {visibleOrderRPAReport ? (
        <OrderReport
          toggleDialog={toggleDialogOrderRPAReport}
          reportName="RPAReportBRY"
          title="Sipariş RPA Formu"
          reportRelative={true}
          orderId={order.brY_SIPARIS_ID}
        ></OrderReport>
      ) : (
        ""
      )} */}
      {visibleOrderRPAReportNew ? (
        <OrderRPAReport
          toggleDialog={toggleDialogOrderRPAReportNew}
          title="Sipariş RPA Formu"
          reportRelative={true}
          order={order}
          sendToRPA={sendToRPA}
        ></OrderRPAReport>
      ) : (
        ""
      )}
      {visibleAddressInfo ? (
        <OrderAddressInfo
          toggleDialog={toggleDialogAdressInfo}
          title="Sipariş Adres Bilgileri"
          orderId={order.brY_SIPARIS_ID}
          order={order}
        ></OrderAddressInfo>
      ) : (
        ""
      )}
      {visibleOrderCopReviseStatus ? (
        <OrderCopReviseStatus
          toggleDialog={toggleDialogOrderCopReviseStatus}
          title="Sipariş Revize"
          data={order}
          onCallback={onCallbackGeriGonder}
        ></OrderCopReviseStatus>
      ) : (
        ""
      )}
      <main style={{ flexGrow: "1", padding: "40px" }}>
        <div>
          <h3>Bireysel Mop Sipariş Detayı</h3>
          <hr />
          <OrderCopProps orderData={order} />
          <hr />
          <Button
            type="button"
            color="secondary"
            size="sm"
            style={{ marginLeft: "5px" }}
            onClick={toggleDialogOrderFiles}
          >
            <i className="k-icon k-i-folder-open"></i> SIPARIS DOSYALARI
          </Button>
          <Button
            type="button"
            color="secondary"
            size="sm"
            style={{ marginLeft: "5px" }}
            onClick={toggleDialogDaikinOrderFiles}
          >
            <i className="k-icon k-i-folder-open"></i>DAIKIN SIPARIS DOSYALARI
          </Button>
          {/* <Button
            type="button"
            color="info"
            size="sm"
            style={{ marginLeft: "5px" }}
            onClick={toggleDialogOrderRPAReport}
          >
            <i className="k-icon k-i-graph"></i> RPA SIPARIS FORMU
          </Button> */}
          <Button
            type="button"
            color="info"
            size="sm"
            style={{ marginLeft: "5px" }}
            onClick={CallDknSapRpaSirapisIslemleri}
          >
            <i className="k-icon k-i-graph"></i> RPA SIPARIS FORMU
          </Button>
          <Button
            type="button"
            color="danger"
            size="sm"
            style={{ marginLeft: "5px" }}
            onClick={toggleDialogResponsiblePerson}
          >
            <i className="k-icon k-i-user"></i> YETKİLİ KİŞİ BİLGİLERİ
          </Button>
          {order?.sevK_ADRESI_TIPI === "YENI_ADRES" ? (
            <Button
              type="button"
              color="warning"
              size="sm"
              style={{ marginLeft: "5px" }}
              onClick={toggleDialogAdressInfo}
            >
              <i className="k-icon k-i-marker-pin"></i> ADRES BİLGİLERİ
            </Button>
          ) : undefined}
          <Button
            color="info"
            type="button"
            size="sm"
            style={{ marginLeft: "5px" }}
            onClick={toggleDialogOrderSAPStatus}
          >
            <span class="k-icon k-i-cart"></span> SAP İşlemleri
          </Button>
          {/* {order?.saP_DURUM !== "REVIZE_TALEP" ? (
            <Button
              type="button"
              color="primary"
              size="sm"
              style={{ marginLeft: "5px" }}
              onClick={sendToRPA}
            >
              <i className="k-icon k-i-redo"></i>{" "}
              {order?.rpA_GONDERILDI === "EVET"
                ? "RPA'E YENİDEN GÖNDER"
                : "RPA'E GONDER"}
            </Button>
          ) : undefined} */}
          <Button
            type="button"
            color="secondary"
            size="sm"
            style={{ marginLeft: "5px" }}
            onClick={toggleDialogSalesApproveFile}
          >
            <i className="k-icon k-i-folder-open"></i>SATIŞ ONAY DOKÜMANI
          </Button>
          <Button
            type="button"
            color="warning"
            size="sm"
            style={{ marginLeft: "5px" }}
            onClick={toggleDialogOrderCopReviseStatus}
          >
            <i className="k-icon k-i-redo"></i>GERİ GÖNDER
          </Button>
          <hr />
          <div>
            <OrderCopProductList
              orderId={order?.brY_SIPARIS_ID}
              orderProductData={props.orderProductList?.orderProducts}
              refreshFunc={refreshProductList}
            ></OrderCopProductList>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    orderProductList: state.orderProductListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getOrderProducts: bindActionCreators(getOrderProducts, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCopUpdate);
