import { Window } from "@progress/kendo-react-dialogs";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReportViewer from "../sys-toolbox/ReportViewer";
import jquery from "jquery";

class OrderRPAReport extends Component {

  addCustomButton = () => {

    if (this.props.order?.saP_DURUM !== "REVIZE_TALEP") {
      const { sendToRPA } = this.props;
      const toolbarNav = jquery("#reportViewer1").find(".trv-nav.k-widget > ul");
  
      if (toolbarNav.length) {
        const RpaButton = `
              <li class="k-item k-state-default d-flex align-items-center" style="background-color: dodgerblue; color: white;">
                <a href="javascript:void(0);" id="RpaBryButton" class="k-link p-1">
                <span class="k-icon k-i-redo"></span>
                   ${this.props.order?.rpA_GONDERILDI === "EVET"
                    ? "RPA'E YENİDEN GÖNDER"
                    : "RPA'E GONDER"}
                </a>
              </li>
            `;
  
        toolbarNav.append(RpaButton);
  
        jquery("#RpaBryButton").on("click", function () {
          if (sendToRPA)
            sendToRPA()
        });
      } else {
        console.error("Toolbar bulunamadı!");
      }
    }

  }

  render() {
    return (<Window
      title={this.props.title}
      onClose={this.props.toggleDialog}
      initialHeight={"85vh"}
      initialWidth={"95%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <ReportViewer
        reportSource={{
          report: "DEX/RPAReport.trdp",
          parameters: {
            teklif_siparis: `SIPARIS`,
            teklif_siparis_id: this.props.order.brY_SIPARIS_ID,
          },
        }}
        addCustomButton={() => this.addCustomButton()}
        openPopup={true}
      ></ReportViewer>
    </Window>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(OrderRPAReport);
