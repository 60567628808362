import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { cloneElement, useEffect, useRef, useState } from "react";
import {
  BASE_URL,
  DATASAPDURUM,
} from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import SapOpenOrderService from "../../services/srv-sap-open-order/SapOpenOrderService";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";

export default function SapOpenOrderCexList() {
  const _grid = useRef(null);
  const sort = [
    {
      field: "saP_SIPARIS_NO",
      dir: "desc",
    },
  ];
  const [cexOrdersLoading, setCexOrdersLoading] = useState(false);
  const [cexOrders, setCexOrders] = useState(null);
  const [cexsDataState, setCexsDataState] = useState({
    take: 10,
    skip: 0,
    sort,
  });

  useEffect(() => {
    getCexOrderList();
  }, []);

  function ChangeIdToText(data, id) {
    return data.find((item) => item.id === id)?.text || id;
  }



  function getCexOrderList() {
    setCexOrdersLoading(true);
    SapOpenOrderService.getCexOrderList(cexsDataState, onGetCexsCompleted);
  }

  function onGetCexsCompleted(data) {
    setCexOrders(data);
    setCexOrdersLoading(false);
  }


  function onDataStateChange(event) {
    filterLocalization(event);
    setCexOrdersLoading(true);
    setCexsDataState(event.dataState);
    SapOpenOrderService.getCexOrderList(event.dataState, onGetCexsCompleted);
  }

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  }

  function goOrderDetail(dataItem) {
    window.open(`${BASE_URL}sap-open-order-cex/${dataItem.saP_SIPARIS_NO}`);
  }

  function SapStatusFilterCell(props) {
    return (
      <DropdownFilterCell
        {...props}
        data={DATASAPDURUM.map((a) => a.id)}
        defaultValue={"Seçiniz"}
      />
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Sap Açık Sipariş Mop Listesi</CardTitle>
      </CardHeader>
      <CardBody>
        {cexOrdersLoading && LoadingPanel}
        <Grid
          ref={_grid}
          data={cexOrders}
          dataItemKey={"saP_SIPARIS_NO"}
          {...cexsDataState}
          onDataStateChange={onDataStateChange}
          sortable={true}
          resizable={true}
          filterable={true}
          style={{ minHeight: "400px", fontSize: "11px" }}
          rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
          pageable={{
            buttonCount: 3,
            pageSizes: [10, 20, 50, 100],
          }}
        >
          <GridColumn
            filterable={false}
            width="100px"
            cell={(props) => (
              <td>
                <button
                  title="Detay"
                  className="btn btn-info btn-sm"
                  onClick={() => goOrderDetail(props.dataItem)}
                >
                  <i className="fas fa-info-circle"></i> Detay
                </button>
              </td>
            )}
          />
          <GridColumn
            field="saP_SIPARIS_NO"
            title="Sap Sipariş No"
            width="150px"
          />
          <GridColumn
            field="bayI_KODU"
            title="Bayi Kodu"
            width="120px"
          />
          <GridColumn
            field="bayI_ADI"
            title="Bayi Adı"
            width="300px"
          />
          <GridColumn
            field="projE_ADI"
            title="Proje Adı"
            width="300px"
          />

          <GridColumn
            field="satiS_TEMSILCISI_ADI"
            width="150px"
            title="Satış Temsilcisi"
          />
            <GridColumn
              field="musterI_ADI"
              width="150px"
              title="Müşteri"
            />
          <GridColumn
            field="sipariS_GIRIS_TARIHI"
            width="150px"
            title="Sipariş Giriş Tarihi"
            filterable={false}
            cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
          />
          <GridColumn
            field="parA_BIRIMI"
            width="150px"
            title="Para Birimi"
          />
          <GridColumn
            field="satiS_GRUBU"
            width="150px"
            title="Satış Grubu"
          />
          <GridColumn
            field="ceX_DURUMU"
            title="Cex Durumu"
            width="150px"
          // filterCell={SapStatusFilterCell}
          // cell={(props) => {
          //   return (
          //     <td>
          //       {ChangeIdToText(DATASAPDURUM, props.dataItem.ceX_DURUMU)}
          //     </td>
          //   );
          // }}
          />
          <GridColumn
            field="iptaL_NEDENI"
            title="Iptal Nedeni"
            width="150px"
          />
          <GridColumn
            field="aciklama"
            title="Açıklama"
            width="150px"
          />
        </Grid>
      </CardBody>
    </Card>
  );
}
