import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";

import { filterBy } from "@progress/kendo-data-query";
import CityService from "../../services/srv-city/CityService";

const CityDropDownList = ({
  id,
  label,
  required,
  onChange,
  filterField,
  filterValue,
  value,
  disabled
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    var filter = [];
    if (filterValue) {
      filter.push({
        field: filterField,
        operator: "eq",
        value: filterValue,
      });
      getDataByFilter(filter);
    }
  }, [filterValue]);

  const getDataByFilter = (filters) => {
    setDataLoading(true);
    CityService.getCitiesGenel(
      {
        filter: { logic: "and", filters: filters },
      },
      defaultDataHandler
    );
  };

  const defaultDataHandler = ({ data }) => {
    data.sort((a, b) => (a.iL_KODU > b.iL_KODU ? 1 : -1));
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData(filterBy(data, e.filter));
  };

  return (
    <DropDownList
      disabled={disabled}
      name={id}
      label={label}
      id={id}
      data={filteredData}
      textField="iL_ADI"
      dataItemKey="iL_KODU"
      style={{ width: "100%" }}
      onChange={(e) => onChange(e)}
      required={required}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      value={value ? data.find((v) => v.iL_KODU === value) : ""}
    ></DropDownList>
  );
};

export default CityDropDownList;
