import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import SapOpenOrderService from "../../services/srv-sap-open-order/SapOpenOrderService";
import { SapOpenOrderProps } from "./SapOpenOrderProps";
import SapOpenOrderProductList from "./SapOpenOrderProductList";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import SapOpenOrderApprovalHistory from "./SapOpenOrderApprovalHistory"



const SapOpenOrderCexUpdate = (props) => {
  const sapNo = props.match.params.id;

  const [orderLoading, setOrderLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [products, setProducts] = useState([])
  const [time, setTime] = useState(120);
  const [showContent, setShowContent] = useState(true);
  const [cexDurum, setCexDurum] = useState({})
  const cexDurumOptions = [
    { text: "YENI", id: "YENI" },
    { text: "ISLEMDE", id: "ISLEMDE" },
    { text: "KAPALI", id: "KAPALI" },
    { text: "IPTAL", id: "IPTAL" },
  ];

  useEffect(() => {
    getSapOpenOrderBySapNo();

    const timerInterval = setTimeInterval()
    return () => clearInterval(timerInterval);

  }, []);

  function setTimeInterval() {
    const timerInterval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 1) {
          clearInterval(timerInterval);
          setShowContent(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return timerInterval;
  }

  function addTime() {
    setTime(time + 60)
  }

  function getSapOpenOrderBySapNo() {
    setOrderLoading(true);
    SapOpenOrderService.getOrderBySapNo(sapNo, onCompletedGetOrderBySapNo);
    SapOpenOrderService.getProductsBySapNo(sapNo, onCompletedGetOrderProducts);
  }

  function onCompletedGetOrderBySapNo(data) {
    setOrder(data);
    setCexDurum({ text: data.ceX_DURUMU, id: data.ceX_DURUMU })
    setOrderLoading(false);
  }

  function onCompletedGetOrderProducts(data) {
    setProducts(data);
  }

  function updateOrder() {
    SapOpenOrderService.updateOrder(order)
  }

  function onHandleChangeStatus(e) {
    setOrder({ ...order, ceX_DURUMU: e.value.text });
    setCexDurum(e.value)
  }

  return (
    showContent ?
      (orderLoading ? LoadingPanel :
        <main style={{ flexGrow: "1", padding: "40px" }}>
          <h3>Mop Sap Açık Sipariş Detay</h3>
          <Card
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          >
            <CardBody>
              <CardTitle>
                <img
                  width="50px"
                  height="50px"
                  alt=""
                  src={
                    window.location.origin +
                    process.env.PUBLIC_URL +
                    "/dex_logo.png"
                  }
                />
                Bu ekranda işlem yapabilmek için 2 dakikanız bulunmaktadır.
              </CardTitle>
              <div style={{ fontSize: "15px", color: "red" }}>
                Kalan Süre : {time}
                <Button
                  type="button"
                  color="primary"
                  size="md"
                  style={{ marginLeft: "20px" }}
                  onClick={() => addTime()}
                >
                  <i className="k-icon k-i-plus-circle"></i> Süre Ekle
                </Button>
              </div>
            </CardBody>
          </Card>

          <hr />
          <SapOpenOrderProps data={order} />
          <hr />
          <Card
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          >
            <CardBody>
              <CardTitle>
                Mop Durumu Güncelle
              </CardTitle>
              <DropDownList
                name="ceX_DURUMU"
                label="Mop DURUMU"
                data={cexDurumOptions}
                textField="text"
                dataItemKey="id"
                style={{ width: "25%" }}
                onChange={(e) => onHandleChangeStatus(e)}
                value={cexDurum}
              />
              <hr />
              <Row>
                <Col lg="4" md="4" xs="12">
                  <Button color="success" onClick={() => updateOrder()} size="md">
                    <i className="fas fa-check-circle mr-1"></i>
                    Durum Güncelle
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <hr />

          <SapOpenOrderProductList
            orderProductData={products}
          ></SapOpenOrderProductList>
          <hr />
          <SapOpenOrderApprovalHistory sapNo={sapNo} />
          <hr />
        </main>)
      : ("Süreniz dolmuştur, lütfen sayfayı yenileyiniz.")

  );
};




export default (SapOpenOrderCexUpdate);
