import axios from "axios";
import {
  API_GATEWAY,
  PR_DEX_BIREYSEL,
} from "../../config/globalVariables";
import { confirmAlert } from "react-confirm-alert";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
} from "../../helpers/utils";
import {
    toDataSourceRequestString,
    translateDataSourceResultGroups,
  } from "@progress/kendo-data-query";

class SapOpenOrderService {
    getOrderBySapNo(sapNo, callback) {
        return axios
            .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/sapaciksiparis/get-by-sapNo/${sapNo}`)
            .then((result) => {
                if (callback) callback(result.data);
                return result.data;
            })
    }

    getCexOrderList(dataState, callback) {
        return axios
            .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/sapaciksiparis/get-cex-order-list?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
    }

    getProductsBySapNo(sapNo, callback) {
        return axios
            .get(
                `${API_GATEWAY + PR_DEX_BIREYSEL
                }/sapaciksiparis/get-products-by-sapNo/${sapNo}`
            )
            .then((result) => {
                if (callback) callback(result.data);
                return result.data;
            })
    }


    updateOrder(order, callback) {
        return axios
            .post(
                `${API_GATEWAY + PR_DEX_BIREYSEL
                }/sapaciksiparis/update-order`,
                {
                    ...order,
                }
            )
            .then((response) => {
              defaultAlertMessages.MessageDetailSuccess = response.message;
              confirmAlert({
                customUI: ({ onClose }) => {
                  return getConfirmSuccessMessage(onClose, defaultAlertMessages);
                },
              });
              if (callback) callback(response);
              return response;
            }).catch((err) => {
              defaultAlertMessages.MessageDetailError = JSON.stringify(
                err.response.data
              );
              confirmAlert({
                customUI: ({ onClose }) => {
                  return getConfirmErrorMessage(onClose, defaultAlertMessages);
                },
              });
            });
    }

    getApprovalHistoryBySapNo(sapNo, callback) {
      return axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/sapaciksiparis/get-approval-history-by-sapNo/${sapNo}`
        )
        .then((response) => {
          if (callback) callback(response.data);
          return response.data;
        });
    }

    executeSapOpenOrderProcess(data, callback) {
        return axios
          .post(
            `${
              API_GATEWAY + PR_DEX_BIREYSEL
            }/sapaciksiparis/execute-sap-open-order-process`,
            data
          )
          .then((response) => {
            defaultAlertMessages.MessageDetailSuccess = response.message;
            confirmAlert({
              customUI: ({ onClose }) => {
                return getConfirmSuccessMessage(onClose, defaultAlertMessages);
              },
            });
            if (callback) callback(response);
            return response;
          })
          .catch((err) => {
            defaultAlertMessages.MessageDetailError = JSON.stringify(
              err.response.data
            );
            confirmAlert({
              customUI: ({ onClose }) => {
                return getConfirmErrorMessage(onClose, defaultAlertMessages);
              },
            });
          });
      }
}

export default new SapOpenOrderService();
