import React from "react";
import { TextArea } from "@progress/kendo-react-inputs";

const CommonTextArea = (props) => {
  return (
    <div style={{ marginTop: "10px" }}>
      {" "}
      {props.label}{" "}
      <TextArea
        readOnly={props.readOnly}
        label={props.label}
        name={props.id}
        id={props.id}
        autoSize={false}
        rows={props.rows ? props.rows : 2}
        style={{ width: "100%" }}
        onChange={props.onChange}
        value={props.value}
        required={props.required}
      ></TextArea>
    </div>
  );
};

export default CommonTextArea;
