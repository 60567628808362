import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import SapOpenOrderService from "../../services/srv-sap-open-order/SapOpenOrderService";
import { SapOpenOrderProps } from "./SapOpenOrderProps";
import SapOpenOrderApproval from "./SapOpenOrderApproval"
import SapOpenOrderProductList from "./SapOpenOrderProductList";
import SapOpenOrderApprovalHistory from "./SapOpenOrderApprovalHistory"
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";



const SapOpenOrderDetail = (props) => {
  const sapNo = props.match.params.id;

  const [orderLoading, setOrderLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [products, setProducts] = useState([])
  const [time, setTime] = useState(120);
  const [showContent, setShowContent] = useState(true);
  const [showApproval, setShowApproval] = useState(false) 
  const [showSatisTemsilcisiApproval, setShowSatisTemsilcisiApproval] = useState(false)
  const kullaniciId= props.auth?.id

  useEffect(() => {
    getSapOpenOrderBySapNo();

    const timerInterval = setTimeInterval()
    return () => clearInterval(timerInterval);

  }, []);

  function setTimeInterval() {
    const timerInterval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 1) {
          clearInterval(timerInterval);
          setShowContent(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return timerInterval;
  }

  function addTime() {
    setTime(time + 60)
  }

  function getSapOpenOrderBySapNo() {
    setOrderLoading(true);
    SapOpenOrderService.getOrderBySapNo(sapNo, onCompletedGetOrderBySapNo);
    SapOpenOrderService.getProductsBySapNo(sapNo, onCompletedGetOrderProducts);
  }

  function onCompletedGetOrderBySapNo(data) {
    setOrder(data);
    var satisTemsilcisiApproval = data.satiS_TEMSILCISI_ID == kullaniciId && data.iptaL_DURUMU === "YENI"
    if (satisTemsilcisiApproval) {
      setShowSatisTemsilcisiApproval(true)
    }
    
    if (satisTemsilcisiApproval || (data.siradakI_ONAYCI_ID == kullaniciId && data.iptaL_DURUMU === "ONAYDA")) {
      setShowApproval(true);
    }
    
    setOrderLoading(false);
  }

  function onCompletedGetOrderProducts(data) {
    setProducts(data);
  }

  function refreshOrder(){
    setShowSatisTemsilcisiApproval(false)
    setShowApproval(false);
    getSapOpenOrderBySapNo()
  }

  return (
      showContent ?
        (orderLoading ? LoadingPanel :
        <main style={{ flexGrow: "1", padding: "40px" }}>
          <h3>Sap Açık Sipariş Detay</h3>
          <Card
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          >
            <CardBody>
              <CardTitle>
                <img
                  width="50px"
                  height="50px"
                  alt=""
                  src={
                    window.location.origin +
                    process.env.PUBLIC_URL +
                    "/dex_logo.png"
                  }
                />
                Bu ekranda işlem yapabilmek için 2 dakikanız bulunmaktadır.
              </CardTitle>
              <div style={{ fontSize: "15px", color: "red" }}>
                Kalan Süre : {time}
                <Button
                  type="button"
                  color="primary"
                  size="md"
                  style={{ marginLeft: "20px" }}
                  onClick={() => addTime()}
                >
                  <i className="k-icon k-i-plus-circle"></i> Süre Ekle
                </Button>
              </div>
            </CardBody>
          </Card>

          <hr />
          <SapOpenOrderProps data={order} />
          <hr />

          <SapOpenOrderProductList
            orderProductData={products}
          ></SapOpenOrderProductList>

          {showApproval && <SapOpenOrderApproval sapNo={sapNo} showSatisTemsilcisiApproval={showSatisTemsilcisiApproval} refreshOrder={() => refreshOrder()} ></SapOpenOrderApproval>}
          <hr />
          <SapOpenOrderApprovalHistory sapNo={sapNo} />
          <hr />
        </main>)
        : ("Süreniz dolmuştur, lütfen mailinizden tekrar giriş yapınız.")

  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};


export default connect(mapStateToProps)(SapOpenOrderDetail);
