import { Row, Col, Button } from "reactstrap";
import { Input, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";


export function SapOpenOrderProps({ data }) {
  return (
    <Card
      style={{
        width: "100%",
        marginTop: "20px",
      }}
    >
      <CardBody>
        <CardTitle>Sipariş Bilgileri</CardTitle>
        <hr />
        <Row>
          <Col lg="4" md="4" xs="12">
            <Input
              label="Sap Sipariş No"
              name="saP_SIPARIS_NO"
              id="saP_SIPARIS_NO"
              style={{ width: "100%" }}
              value={data.saP_SIPARIS_NO}
              readOnly={true}
            ></Input>
            <Input
              name="bayI_ADI"
              label="Bayi Kodu / Adı "
              style={{ width: "100%" }}
              value={data.bayI_KODU +" / " +data.bayI_ADI}
              readOnly={true}
            />
            <Input
              name="projE_ADI"
              label="Proje Adı"
              style={{ width: "100%" }}
              value={data.projE_ADI}
              readOnly={true}
            />
            <Input
              label="Satış Temsilcisi"
              name="satiS_TEMSILCISI_ADI"
              id="satiS_TEMSILCISI_ADI"
              style={{ width: "100%" }}
              value={data.satiS_TEMSILCISI_ADI}
              readOnly={true}
            ></Input>
          </Col>
          <Col lg="4" md="4" xs="12">
             <DateInput
                label="Sipariş Giriş Tarihi"
                format="dd.MM.yyyy"
                formatPlaceholder="formatPattern"
                value={new Date(data.sipariS_GIRIS_TARIHI)}
                style={{ width: "100%" }}
              />
            <Input
              name="parA_BIRIMI"
              label="Para Birimi"
              style={{ width: "100%" }}
              value={data.parA_BIRIMI}
              readOnly={true}
            />
            <Input
              name="satiS_GRUBU"
              label="Satış Grubu"
              style={{ width: "100%" }}
              value={data.satiS_GRUBU}
              readOnly={true}
            />
            <Input
              label="Musteri"
              name="musterI_ADI"
              id="musterI_ADI"
              style={{ width: "100%" }}
              value={data.musterI_ADI}
              readOnly={true}
            ></Input>
          </Col>
          <Col lg="4" md="4" xs="12">
            <Input
              name="iptaL_DURUMU"
              label="İşlem Durumu"
              style={{ width: "100%" }}
              value={data.iptaL_DURUMU}
              readOnly={true}
            />
            <Input
              name="iptaL_NEDENI"
              label="İptal Edilmeme Sebebi"
              style={{ width: "100%" }}
              value={data.iptaL_NEDENI}
              readOnly={true}
            />
            <div>
              <div>Açıklama</div>
              <TextArea
                placeholder="Açıklama"
                id="aciklama"
                name="aciklama"
                autoSize={false}
                style={{ width: "100%" }}
                value={data.aciklama}
                readOnly={true}
              ></TextArea>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
