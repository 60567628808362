import React, { useEffect, useRef, useState } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import { CardHeader } from "reactstrap";
import ButceKoduService from "../../services/srv-butceKodu/ButceKoduService";
import filterLocalization from "../../helpers/filterLocalization";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import ExportExcel from "../sys-toolbox/ExcelExport";
import { ButceKoduColumns } from "./ButceKoduColumns";
import { LockedMyCommandCellWithoutDelete } from "../sys-toolbox/LockedGridInlineEditWitouthDelete";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import { confirmAlert } from "react-confirm-alert";
import ButceKoduExcelUpload from "./ButceKoduExcelUpload";

function ButceKoduList() {
  const _grid = useRef(null);
  const editField = "inEdit";
  const [listData, setListData] = useState();
  const search = "";
  const primaryKey = "budgeT_CODE";
  const [originalListData, setOriginalListData] = useState();

  // State tanımlamaları
  const [listDataLoading, setListDataLoading] = useState(false);

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    ...search,
  });

  const dataStateChange = (event) => {
    filterLocalization(event);
    setDataState(event.dataState);
  };

  // useEffect(() => {
  //   console.log("List Data Güncellendi:", listData);
  // }, [listData]);

  useEffect(() => {
    setListDataLoading(true);
    ButceKoduService.getButceKoduList(dataState, (data) => {
      // debugger;

      if (data) {
        // Kolonlara göre filtreleme
        // const visibleColumns = [
        //   "budgeT_CODE",
        //   "aciklama",
        //   "artI_FREE_STOCK_GUN",
        // ]; // Grid kolonları
        // const filteredData = data.data.map((item) =>
        //   visibleColumns.reduce((acc, key) => {
        //     acc[key] = item[key];
        //     return acc;
        //   }, {})
        // );
        // setListData(filteredData);
        // setOriginalListData(filteredData); // Geri alma işlemleri için
        setListData(data);
        setOriginalListData(data); // Geri alma işlemleri için
        setListDataLoading(false);
      }
    });
  }, [dataState]);

  function OnCompletedGetList(data) {
    if (data) {
      setListData(data);
      setOriginalListData(data);
      //console.log("LİST DATA :  " + listData);
      if (data.dataState) setDataState(data.dataState);
      setListDataLoading(false);
    }
  }

  const [visibleExcelUpload, setVisibleExcelUpload] = useState(false);
  const excelToggle = () => {
    setVisibleExcelUpload(!visibleExcelUpload);
  };

  const enterEditCommand = (dataItem) => {
    var isOpenEdit = listData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = listData.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setListData( {...listData,data} );
    }
  };

  const addCommand = (dataItem) => {
    ButceKoduService.insert(dataItem, OnCompletedGetList);
    // dataItem.inEdit = false;
    // updateListData(dataItem)
  };

  const discardCommand = (dataItem) => {
    //console.log("discardCommand")
    const data = listData.data;
    data.splice(0, 1);
    setListData( {...listData,data} );
  };

  const updateCommand = (dataItem) => {
    ButceKoduService.update(dataItem, undefined);
    dataItem.inEdit = false;
    updateListData(dataItem)
  };

  const cancelCommand = (dataItem) => {
    const originalItem = originalListData.data.find(
      (p) => p[primaryKey] === dataItem[primaryKey]
    );
    updateListData(originalItem)
  };

  const updateListData = (dataItem) => {
    const data = listData.data.map((item) =>
      item[primaryKey] === dataItem[primaryKey] ? dataItem : item
    );
    setListData( {...listData,data} );
  }
  //DÜZENLEME BUTONU
  const CommandCell = (props) => {
    return (
      <LockedMyCommandCellWithoutDelete
        {...props}
        edit={enterEditCommand}
        add={addCommand}
        discard={discardCommand}
        update={updateCommand}
        cancel={cancelCommand}
        editField={editField}
        idField={primaryKey}
        nameField={primaryKey}
      />
    );
  };

  const onItemChange = (event) => {
    const data = listData.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setListData( {...listData,data} );
  };

 const rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };
  
  return (
    <main style={{ flexGrow: "1" }}>
      {visibleExcelUpload ? (
        <ButceKoduExcelUpload modalToggle={excelToggle}></ButceKoduExcelUpload>
      ) : (
        ""
      )}
      <h2>Bütçe Kodu Listesi</h2>
      {/* <Card>
        <CardHeader>
          <CardTitle>Bütçe Kodu</CardTitle>
        </CardHeader>
        <CardBody> */}
          {listDataLoading && LoadingPanel}
          <Grid
            id="grid"
            ref={_grid}
            data={listData} // State üzerinden Grid'e veri sağlanır
            {...dataState}
            onDataStateChange={dataStateChange}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{
              buttonCount: 3,
              pageSizes: [10, 20, 30],
            }}
            filterable={true}
            editField={editField}
            dataItemKey={primaryKey}
            onItemChange={onItemChange}
            rowRender={(trElement, dataItem) =>
              rowRender(trElement, dataItem)
            }
          >
            <GridToolbar>
              <ExportExcel
                data={listData}
                columns={ButceKoduColumns}
                loadingFunction={setListDataLoading}
                dataState={dataState}
                serviceMethod={ButceKoduService.getListForExcel}
                fileName="ButceKoduListesi"
              ></ExportExcel>
              <button
                title="Excel ile Toplu Güncelle"
                className="btn btn-primary btn-sm"
                onClick={() => excelToggle()}
              >
                Excel ile Toplu Güncelle
              </button>
            </GridToolbar>

            {/* Grid Kolonları */}
            <GridColumn
              width="100px"
              filterable={false}
              cell={CommandCell}
              locked={true}
            />
            <GridColumn
              field="budgeT_CODE"
              title="Bütçe Kodu"
              width="200px"
              locked={true}
              editable={false}
            />
            <GridColumn
              field="aciklama"
              title="Açıklama"
              width="200px"
              editor={"text"}
              locked={true}
            />
            <GridColumn
              field="artI_FREE_STOCK_GUN"
              title="Artı Gün"
              width="200px"
              editor={"numeric"}
              locked={true}
            />
          </Grid>
        {/* </CardBody>
      </Card> */}
    </main>
  );
}

export default ButceKoduList;
