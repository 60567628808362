import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import NeighbourhoodService from "../../services/srv-province/ProvinceService";

const NeighbourhoodDropDownList = ({
  id,
  label,
  required,
  onChange,
  value,
  filterValue,
  dataItemKey,
  disabled
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    if (filterValue) {
      var filter = [];
      filter.push({
        field: "ilcE_TANIM_ID",
        operator: "eq",
        value: filterValue,
      });
      getDataByFilter(filter);
    } else if (!filterValue) {
      setDataLoading(false);
    }
  }, [filterValue]);

  const getDataByFilter = (filters) => {
    setDataLoading(true);
    NeighbourhoodService.getNeighbourhoods(
      {
        filter: { logic: "and", filters: filters },
      },
      defaultDataHandler
    );
  };

  const defaultDataHandler = ({ data }) => {
    // data.sort((a, b) => (a.daikiN_ILCE_ADI > b.daikiN_ILCE_ADI ? 1 : -1));
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.mahallE_ADI.toLowerCase().includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      disabled={disabled}
      name={id}
      id={id}
      label={label ? label : undefined}
      data={filteredData}
      textField="mahallE_ADI"
      dataItemKey={"geneL_MAHALLE_TANIM_ID"}
      style={{ width: "100%" }}
      onChange={(e) => onChange(e)}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      value={value ? data.find((v) => v.geneL_MAHALLE_TANIM_ID === value) : value}
    ></DropDownList>
  );
};

export default NeighbourhoodDropDownList;
