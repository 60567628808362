import { Window } from "@progress/kendo-react-dialogs";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Button, Col, Row } from "reactstrap";
import { DATADIL } from "../../config/globalVariables";
import {
  defaultAlertMessages,
  getConfirmSuccessMessage,
} from "../../helpers/utils";
import CustomerService from "../../services/srv-customer/CustomerService";
import OrderAddressService from "../../services/srv-order/OrderAddressService";
import CityDropDownList from "../sys-common/CityDropDownList";
import CommonDropDownList from "../sys-common/CommonDropDownList";
import CommonInput from "../sys-common/CommonInput";
import CommonTextArea from "../sys-common/CommonTextArea";
import CountryDropDown from "../sys-common/CountryDropDown";
import NeighbourhoodDropDownList from "../sys-common/NeighbourhoodDropDownList";
import OfferOrderRegionDropDownList from "../sys-common/OfferOrderRegionDropDownList";
import ProvinceDropDownList from "../sys-common/ProvinceDropDownList";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

const CustomerAddressUpdate = ({
  type,
  musId,
  musAdres,
  toggleDialog,
  bayiKodu,
  title,
  orderId,
  isExport,
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (bayiKodu) {
      getMusId();
    }
    type == "update"
      ? setData(musAdres)
      : setData({ ...data, muS_TANIM_ID: musId });
  }, []);

  const getMusId = () => {
    CustomerService.getCustomerIdByBayiKodu(bayiKodu, dataHandler);
  };
  const dataHandler = (musTanimId) => {
    setData({
      ...data,
      muS_TANIM_ID: parseInt(musTanimId),
      brY_SIPARIS_ID: parseInt(orderId),
    });
  };
  const callbackAfterUpdate = (response) => {
    if (type == "siparis-insert") {
      OrderAddressService.upsertOrderAddressInfoWithOrder(
        data,
        window.location.reload(),
        null
      );
    }

    setDataLoading(false);
    toggleDialog();
    defaultAlertMessages.MessageDetailSuccess = response;
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmSuccessMessage(onClose, defaultAlertMessages);
      },
    });
    return;
  };

  const onErrorCallback = (response) => {
    setDataLoading(false);
    defaultAlertMessages.MessageDetailSuccess = response;
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmSuccessMessage(onClose, defaultAlertMessages);
      },
    });
    return;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setDataLoading(true);
    if (!data.adreS_TIPI) 
      data.adreS_TIPI = "PROJE"
    
     CustomerService.upsertCustomerAddressInfo(
       data,
       callbackAfterUpdate,
       onErrorCallback
     );
  };

  return (
    <Window
      title={title || "Müşteri Adres Güncelle"}
      onClose={toggleDialog}
      initialHeight={"70vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
        position: "fixed",
      }}
    >
      <form onSubmit={onSubmit} className="k-form">
        {dataLoading && LoadingPanel}
        <Row>
          <Col lg="6" md="6" xs="12">
            <CommonInput
              label="Adres Başlığı (Firma Bilgisi)"
              id="adreS_BASLIGI"
              required={true}
              onChange={(e) =>
                setData({
                  ...data,
                  adreS_BASLIGI: e.value.toUpperCase(),
                  sevK_ADI: e.value.toUpperCase(),
                })
              }
              value={data.adreS_BASLIGI}
            ></CommonInput>
            {/* <CommonTextArea
              label="Adres 1 (mahalle/sokak/cadde)"
              id="adreS_1"
              onChange={(e) =>
                setData({
                  ...data,
                  adreS_1: e.value.toUpperCase(),
                })
              }
              value={data.adreS_1}
              required={true}
            ></CommonTextArea> */}
            <CommonTextArea
              label="Adres 2 (mahalle/sokak/cadde)"
              id="adreS_2"
              required={true}
              onChange={(e) => {
                setData({
                  ...data,
                  adreS_2: e.value.toUpperCase(),
                });
              }}
              value={data.adreS_2}
            ></CommonTextArea>
            <CommonTextArea
              label="Adres 3 (mahalle/sokak/cadde)"
              id="adreS_3"
              onChange={(e) =>
                setData({
                  ...data,
                  adreS_3: e.value.toUpperCase(),
                })
              }
              value={data.adreS_3}
            ></CommonTextArea>
            <CommonInput
              id="no"
              label="Kapı No"
              required={!isExport}
              onChange={(e) => setData({ ...data, no: e.value.toUpperCase() })}
              value={data.no}
            ></CommonInput>
          </Col>
          <Col lg="6" md="6" xs="12">
            <CountryDropDown
              label="Ülke"
              id="ulkE_KODU"
              onChange={(e) => {
                setData({
                  ...data,
                  ulkE_KODU: e.target.value.ulkE_KODU,
                  iL_KODU: "",
                  ilcE_KODU: "",
                  bolgE_KODU: "",
                  mahallE_TANIM_ID: 0,
                  ilcE_TANIM_ID: 0,
                  postA_KODU: "",
                  timezone: e.target.value.timezone,
                  tasimA_BOLGESI_KODU: e.target.value.tasimA_BOLGESI_KODU,
                  dil:
                    e.target.value.ulkE_KODU === "TR"
                      ? "TR Turkish"
                      : "EN English",
                });
              }}
              value={data.ulkE_KODU}
              required={true}
            ></CountryDropDown>
            <CityDropDownList
              id="iL_KODU"
              label="İl"
              filterField={"ulkE_KODU"}
              filterValue={data.ulkE_KODU}
              value={data.iL_KODU}
              disabled={!data || !data.ulkE_KODU}
              onChange={(e) => {
                setData({
                  ...data,
                  iL_KODU: e.target.value.iL_KODU,
                  ilcE_KODU: "",
                  mahallE_TANIM_ID: 0,
                  tasimA_BOLGESI:
                    data.ulkE_KODU === "TR"
                      ? e.target.value.ulkE_KODU + e.target.value.plakA_KODU
                      : "",
                  bolgE_KODU:
                    data.ulkE_KODU === "TR" ? e.target.value.plakA_KODU : "",
                });
              }}
              required={true}
            />
            {data.ulkE_KODU === "TR" && (
              <CommonInput
                id="bolgE_KODU"
                label="Plaka Kodu"
                value={data.bolgE_KODU}
                readOnly={true}
              ></CommonInput>
            )}
            {data.ulkE_KODU === "TR" && (
              <ProvinceDropDownList
                id="ilcE_KODU"
                label="İlçe"
                filterValue={data.iL_KODU}
                value={data.ilcE_TANIM_ID}
                disabled={!data || !data.iL_KODU}
                onChange={(e) => {
                  setData({
                    ...data,
                    ilcE_KODU: e.target.value.ilcE_ADI,
                    ilcE_TANIM_ID: e.target.value.geneL_ILCE_TANIM_ID,
                  });
                }}
                required={!isExport}
              />
            )}
            {data.ulkE_KODU === "TR" && (
              <NeighbourhoodDropDownList
                id="geneL_MAHALLE_TANIM_ID"
                label="Mahalle"
                filterValue={data.ilcE_TANIM_ID}
                value={data.mahallE_TANIM_ID}
                disabled={!data || !data.ilcE_TANIM_ID}
                onChange={(e) => {
                  setData({
                    ...data,
                    mahallE_TANIM_ID: e.target.value.geneL_MAHALLE_TANIM_ID,
                    mahallE_ADI: e.target.value.mahallE_ADI,
                    postA_KODU: e.target.value.postA_KODU,
                  });
                }}
              />
            )}
            {isExport ? (
              <OfferOrderRegionDropDownList
                id="bolgE_KODU"
                label="Bölge"
                value={data?.bolgE_KODU}
                onChange={(e) => {
                  setData({
                    ...data,
                    bolgE_KODU: e.target.value.bolgE_KODU,
                  });
                }}
              ></OfferOrderRegionDropDownList>
            ) : undefined}
            <CommonDropDownList
              id="dil"
              label="Dil"
              data={DATADIL}
              onChange={(e) => setData({ ...data, dil: e.target.value.text })}
              required={true}
              value={data.dil}
            />
            {data.ulkE_KODU === "TR" && (
              <CommonInput
                id="postA_KODU"
                label="Posta Kodu"
                required={!isExport}
                readOnly={true}
                onChange={(e) => setData({ ...data, postA_KODU: e.value })}
                value={data.postA_KODU}
              ></CommonInput>
            )}
            <CommonInput
              id="sevK_SORUMLUSU"
              label="Sevk Sorumlusu"
              required={true}
              onChange={(e) =>
                setData({
                  ...data,
                  sevK_SORUMLUSU: e.value.toUpperCase(),
                })
              }
              value={data.sevK_SORUMLUSU}
            ></CommonInput>
            <MaskedTextBox
              label="Sevk Sorumlusu Telefon"
              name="sevK_SORUMLUSU_TELEFON"
              id="sevK_SORUMLUSU_TELEFON"
              mask="0000000000"
              required={true}
              value={
                data.sevK_SORUMLUSU_TELEFON ? data.sevK_SORUMLUSU_TELEFON : ""
              }
              onChange={(e) =>
                setData({ ...data, sevK_SORUMLUSU_TELEFON: e.value })
              }
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg="12" md="12" xs="12" style={{ textAlign: "center" }}>
            <Button color="success" type="submit" size="md">
              <span class="fas fa-check-circle"></span>
              Kaydet
            </Button>
          </Col>
        </Row>
      </form>
    </Window>
  );
};

export default CustomerAddressUpdate;
